@import-normalize @import 'styles/_colors.scss';
html {
  font-size: 16px; }

@media (min-width: 768px) {
  html {
    font-size: 18px; } }

.card-deck .card {
  min-width: 220px; }

section {
  padding: 70px 0;
  font-size: 18px;
  color: #3f4141;
  background-color: white;
  background: linear-gradient(to top right, white, #f3f3f3); }

section.primary {
  color: white;
  background-color: #fd3b37;
  background: linear-gradient(to bottom right, #fd3b37, #fe3682); }

section.dark {
  color: white;
  background: #3f4141; }

section.dark a {
  color: #fd3b37; }

section.primary a {
  color: #3f4141; }

section h1 {
  font-weight: 300; }

div#ual-box {
  line-height: 1.25; }

div#ual-box h1 {
  font-size: 1.7em; }

/**
 * Hero section at the top
 */
body {
  background-size: 100%;
  background-repeat: no-repeat; }

button[disabled] {
  cursor: not-allowed; }

.Home .ref img {
  max-width: 100%;
  max-height: 50px;
  margin: 25px 0; }

.Home span.highlight {
  background: white;
  color: #fd3b37;
  padding: 1px 7px;
  border-radius: 5px;
  font-weight: 400; }

.Home span.highlight.dark {
  color: #3f4141; }

.Home .communityReview blockquote {
  font-size: 1em;
  color: #3f4141;
  margin-bottom: 0px; }

.Home .communityReview blockquote footer {
  font-size: .8em; }

.Home .communityReview .card {
  margin-top: 25px; }
